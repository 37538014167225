"use client";
import React from "react";

import styles from "./clientstyles.module.scss";

import Marquee from "react-fast-marquee";
import WrapperContainer from "@/modules/common/components/max-width-wrapper";

const cardsData = [
  {
    text: "Neurable",
    imageUrl: "./logo/logo-neurable.svg",
  },
  // {
  //   text: "BioAro",
  //   imageUrl: "./logo/bioaro-black.svg",
  // },
  {
    text: "HealthSpan",
    imageUrl: "./logo/healthspan-digital-logo.svg",
  },
  {
    text: "Patchflow",
    imageUrl: "./logo/patchflow-logo.svg",
  },

  {
    text: "SEQUENCING",
    imageUrl: "./logo/logo-sequencing.svg",
  },
  {
    text: "STAYABOVE",
    imageUrl: "./logo/logo-stayabove.svg",
  },
  {
    text: "TruDiagnostic",
    imageUrl: "./logo/trudiagnostic.svg",
  },

  {
    text: "JUNG+",
    imageUrl: "./logo/logo-jung.svg",
  },
];

const Clients = () => {
  return (
    <div
      className={` ${styles.clients_container} w-full px-5 sm:px-14 py-16 bg-customDarkGreen`}
    >
      <WrapperContainer>
        <div className="my-8 mt-0">
          <h2
            className={`${styles.header_text} text-2xl text-center text-black font-sans mb-6 mx-auto`}
          >
            Geneseq Marketplace is a B2B online broker connecting vendors and
            licensees via innovative longevity and precision medicine products.
          </h2>
        </div>
        <div className="my-8">
          <p
            className={`${styles.text} text-2xl text-center text-black font-sans mb-14 mx-auto`}
          >
            Our expanding product portfolio caters to a wide range of needs —
            from biomarkers of aging to solutions that enhance mental, physical,
            and emotional performance markers. Explore the current offerings in
            the shop, and join us in setting new standards for technology
            enabled personalized wellness.
          </p>
        </div>
      </WrapperContainer>
      <Marquee>
        <div className="flex flex-nowrap justify-between">
          {cardsData.map((card, index) => (
            <div
              key={index}
              className={`${styles.card_container} w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 px-2 sm:px-4 mt-8`}
            >
              <div className=" rounded-lg shadow-md p-6 min-h-32 items-center	 flex flex-col justify-center bg-customYellow">
                <img
                  src={card.imageUrl}
                  alt={card.text}
                  className="mx-auto max-h-12"
                />
              </div>
            </div>
          ))}
        </div>
      </Marquee>
    </div>
  );
};

export default Clients;
